const Image = ({ ...property }) => {
    return (
        <img
            onClick={property.onClick}
            src={property.src}
            width={property.width}
            height={property.height}
            className={property.className}
            {...property}
        />
    );
};

export default Image;
