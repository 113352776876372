import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import { InvestmentOptionsData } from "@helpers/homepageData";
import Image from "@atoms/Image";
import OptionCard from "./OptionCard";
import useWindowDimensions from "@hooks/useWindowDimensions";

const InvestmentOptions = () => {
    const { width } = useWindowDimensions();
    return (
        <div className="relative overflow-hidden bg-primary-500 py-12 md:pt-14 xl:py-18 md:pb-16 px-4 md:px-6 xl:px-16">
            <div className="max-width-wrapper">
                <Text content={texts?.InvestmentOptionsHeader} className="max-w-[250px] md:max-w-none md:text-center text-basicWhite h4-semibold md:h3-semibold xl:h2-semibold" htmlTag="h2"/>
                <div className="mt-12 xl:mt-[72px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-14">
                    {
                        InvestmentOptionsData?.map((item, index) => (
                            <OptionCard key={index} details={item} />
                        ))
                    }
                </div>
            </div>
            <Image src={width < 768 ? '/images/background/jiraaf-arrow-orange-mobile.svg' : '/images/background/jiraaf-arrow-orange.svg'} className="absolute top-9 md:top-auto right-0 md:bottom-0" data-aos='fade-up-left'
            />
        </div>
    );
};

export default InvestmentOptions;