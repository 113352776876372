import Image from "@atoms/Image";
import Text from "@atoms/Text";

const OptionCard = ({ details }) => {
    const { image, header, description } = details;
    return (
        <div className="flex flex-row md:flex-col gap-x-4">
            <Image src={image} className="w-10 md:w-12 h-10 md:h-12" />
            <div>
                <Text content={header} className="md:mt-2 text-secondary-400 h6-semibold md:h5-bold" htmlTag="h3" />
                <Text content={description} className="mt-2 text-basicWhite p4-regular" />
            </div>
        </div>
    );
};

export default OptionCard;